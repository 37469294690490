<template>
  <div>
    <v-container>
      <ArticleView :pageitems="pageitems" :pagetitle="pagetitle" />
    </v-container>     
  </div>
</template>

<script>
import { get } from "@/apiClient";
import ArticleView from "@/components/ArticleView.vue";
export default {
  components: {
    ArticleView,
  },
  data: () => ({
    pagetitle: "",
    pageitems: [],
  }),
  methods: {
  },
  mounted: async function () {
    const route = this.$route;
    get("article/" + route.params.id).then((response) => {
      this.pagetitle = response.data.title;
      this.pageitems = response.data.items;
    });
  },
};
</script>

<style>
</style>