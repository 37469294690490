<template>
  <div class="content-wrapper">
<!--    <TopBarWhiteBackground />-->
    <div class="screen-title">
      <v-container class="d-flex align-center justify-lg-space-between max-w-100">
        <h2>Article Dashboard</h2>
        <v-col cols="auto" class="pa-0">
          <!-- <v-btn color="primary" class="mr-5">
            <v-icon  class="mdi mdi-refresh "></v-icon>
          </v-btn> -->
          <v-btn @click="$router.push('editor/new')" color="primary">
            <v-icon class="mdi mdi-plus mr-1"></v-icon>
            new article
          </v-btn>
        </v-col>
      </v-container>
    </div>
    <!--//-->
    <v-container class="pt-0 max-w-100">
      <div class="table-card">
        <!-- <div class="filter-search align-start">
          <v-text-field color="primary" class="search-box" label="Search"
                        prepend-inner-icon="mdi-magnify" variant="outlined"></v-text-field>
          <div class="mobile-view-space">
            <v-btn class="mt10 ml-3" color="primary">Filter Today</v-btn>
            <v-btn class="mt10 ml-3" color="primary">Clear</v-btn>
          </div>
        </div> -->
        <v-data-table
            v-model:items-per-page="itemsPerPage"
            :headers="headers"
            :items="articles"
            item-value="name"
            class="elevation-1 bottom-space"
        >
          <template v-slot:[`item.action`]="{item}">
            <!-- {{ item }} -->
            <v-btn class="extra-small-size" icon="mdi-pencil" color="primary" size="small" @click="$router.push('editor/' + (item.key || item.selectable.key))"></v-btn>
            <v-btn class="extra-small-size ml-2" icon="mdi-file-find" color="primary" size="small" @click="$router.push('preview/' + (item.key || item.selectable.key))"></v-btn>
            <!-- <v-btn class="extra-small-size ml-1" icon="mdi-trash-can-outline" color="primary" size="small"></v-btn> -->
          </template>
        </v-data-table>
      </div>
    </v-container>
<!--    <FooterView />-->
  </div>
</template>


<script>
// import { useRoute, useRouter } from "vue-router";
// import TopBarWhiteBackground from "@/components/TopBarWhiteBackground.vue";
// import FooterView from "@/components/Footer.vue";
import { VDataTable } from "vuetify/labs/VDataTable";
import { get } from "@/apiClient";
export default {
  components: {
    // TopBarWhiteBackground,
    // FooterView,
    VDataTable
  },
  name: "DashboardView",
  mounted: async function () {
    get("articleall/" + this.$route.params.siteid)
        .then((response) => {
          this.articles = response.data;
        })
        .catch((err) => console.log(err));
  },
  data: () => ({
    itemsPerPage: 50,
    articles: [],
    headers: [
      {
        title: 'Title',
        align: 'start',
        sortable: true,
        key: 'title',
      },
      { title: 'Category', align: 'center', key: 'subcat' },
      // { title: 'Fat (g)', align: 'center', key: 'fat' },
      // { title: 'Carbs (g)', align: 'center', key: 'carbs' },
      // { title: 'Protein (g)', align: 'center', key: 'protein' },
      // { title: 'Iron (%)', align: 'center', key: 'iron' },
      { title: 'Action', align: 'center', key: 'action' },
    ],
  }),
};
</script>

<style scoped></style>