<template>
  <authenticator>
    <template v-slot="{ user, signOut }">
      <v-container>
        <h1>Hello {{ user.attributes.email }}!</h1>
        <div v-for="site of sites" :key="site">
          <router-link :to="`/${site.url}`">{{ site.title }}</router-link>

        </div>

        <button  @click="signOut">Sign Out</button>
      </v-container>
    </template>
  </authenticator>
</template>
<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
</script>
<script>
import { Auth, Hub } from "aws-amplify";
import {  get } from "@/apiClient";
export default {
  data: () => ({
    sites: [
      // "ahmadiyyareferences.org",
      // "ahmadiyyauncovered.org",
    ],
  }),
  mounted: async function () {

  
    // Hub.listen("auth", (data) => {
      // const { payload } = data;
      // this.onAuthEvent(payload);
      Auth.currentSession()
        .then((data) => {
          console.log(data);
          localStorage.setItem("token", data.idToken.jwtToken);
          get("sites")
            .then((response) => {
              // this.articles = response.data;
              this.sites = response.data;
              console.log(response.data);
            })
            .catch((err) => console.log(err));  
          this.$router.push({ name: "Home" });
        })
        .catch((err) => console.log(err));
    // });
  },
};
</script>

<style>
</style>