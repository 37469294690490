<template>
  <div class="content-wrapper">
    <div class="pa-4">
      <h1>Editor</h1>
      <div>
        <v-text-field
          label="Title"
          variant="outlined"
          v-model="pagetitle"
        ></v-text-field>
      </div>
      <div v-for="(itm, i) of pageitems" :key="i" class="mb-10 sectionheading">
        <div v-if="itm.type === 'card'">
          <div class="d-flex align-center mb-5">
            <h2>Card</h2>
            <v-btn
              outlined
              :disabled="i === 0 && pageitems.length > 1"
              @click="moveItemUp(itm, i)"
              class="ml-3"
            >
              <v-icon class="drag-icon"> mdi-arrow-up </v-icon>
            </v-btn>
            <v-btn
              outlined
              :disabled="
                index === itm.value.length - 1 && itm.value.length > 1
              "
              @click="moveItemDown(itm, i)"
              class="ml-2"
            >
              <v-icon class="drag-icon"> mdi-arrow-down </v-icon>
            </v-btn>
            <v-btn @click="deleteItem(i)" color="primary" class="ml-5">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
          <v-row gutters="16">
            <div class="d-flex align-center">
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                label="SM"
                variant="outlined"
                v-model="itm.widthsm"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                label="MD"
                variant="outlined"
                v-model="itm.widthmd"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                label="LG"
                variant="outlined"
                v-model="itm.widthlg"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-select
                label="Style"
                :items="cardstyles"
                v-model="itm.style"
              ></v-select>
            </v-col>
            </div>
            <!-- itm.value is array with title,link and content fields -->
            <v-col cols="12" sm="12" md="12" lg="12" v-for="(card, cidx) of itm.value" :key="card.title">
              <div class="d-flex align-center">
                <h3>Card {{cidx + 1}}</h3>
                <!-- add delete botton -->
                <!-- move up andmove buttons -->
                <v-btn
                  outlined
                  :disabled="(cidx === 0 && itm.value.length > 1) || (cidx === 0 && itm.value.length === 1)"
                  @click="moveCardItemUp(itm.value, cidx)"
                  class="ml-3"
                >
                  <v-icon class="drag-icon"> mdi-arrow-up </v-icon>
                </v-btn>
                <v-btn
                  outlined
                  :disabled="
                    (cidx === itm.value.length - 1 && itm.value.length > 1) || (cidx === 0 && itm.value.length === 1)
                  "
                  @click="moveCardItemDown(itm.value, cidx)"
                  class="ml-2"
                >
                  <v-icon class="drag-icon"> mdi-arrow-down </v-icon>
                </v-btn>
                <v-btn
                  @click="deleteCardItem(itm.value, cidx)"
                  color="primary"
                  class="ml-5"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
              
            <v-col cols="12" sm="12" md="12" lg="12">
              <div class="d-flex align-center">
              <v-text-field
                label="Title"
                variant="outlined"
                v-model="card.header"
              ></v-text-field>
            
              <v-text-field
                label="Link"
                variant="outlined"
                v-model="card.link"
                class="ml-5"
              ></v-text-field>
              <v-switch
                v-model="card.linknewwindow"
                label="New Window"
                color="primary"
                class="ml-15"
                @change="saveArticle"
              >
              </v-switch>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <div class="position-relative">
                <v-md-editor
                  v-model="card.content"
                  height="200px"
                  @save="editorSave"
                  left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link | save"
                ></v-md-editor>
                <v-btn
                  class="file-upload-btn"
                  color="primary"
                  fab
                  x-small
                  dark
                  @click="openRefGetter(itm.value, 2)"
                >
                  <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
              </div>
            </v-col>
              
            </v-col >
            <v-col cols="12" sm="12" md="12" lg="12">
          
              <v-btn
                class="ml-5"
                @click="addCardItem(itm.value)"
                color="primary"
              >Add card</v-btn>
            </v-col>

          </v-row>
        </div>
        <v-row gutters="16" v-if="itm.type === 'text'">
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="d-flex align-center mb-5">
              <h2>Text Section</h2>
              <v-btn
                outlined
                :disabled="i === 0 && pageitems.length > 1"
                @click="moveItemUp(itm, i)"
                class="ml-3"
              >
                <v-icon class="drag-icon"> mdi-arrow-up </v-icon>
              </v-btn>
              <v-btn
                outlined
                :disabled="
                  index === itm.value.length - 1 && itm.value.length > 1
                "
                @click="moveItemDown(itm, i)"
                class="ml-2"
              >
                <v-icon class="drag-icon"> mdi-arrow-down </v-icon>
              </v-btn>
              <v-btn @click="deleteItem(i)" color="primary" class="ml-5">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <div class="d-flex align-center mb-5">
              <v-text-field
                label="Title (Optional)"
                variant="outlined"
                v-model="itm.title"
              ></v-text-field>
              <v-switch
                v-model="itm.collapsed"
                label="Collapsed"
                color="primary"
                class="ml-15"
                @change="saveArticle"
              >
              </v-switch>
            </div>
            <div class="position-relative">
              <v-md-editor
                @save="editorSave"
                left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link | save"
                v-model="itm.value"
                height="400px"
              ></v-md-editor>
              <v-btn
                class="file-upload-btn"
                color="primary"
                fab
                x-small
                dark
                @click="openRefGetter(itm, 'value')"
              >
                <v-icon>mdi-cloud-upload</v-icon>
              </v-btn>
            </div>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <Markdown
              class="pt-15"
              :breaks="true"
              :html="true"
              :source="cleanText(itm.value)"
            />
          </v-col> -->
          <!-- <v-md-editor v-model="itm.value" height="400px"></v-md-editor> -->
        </v-row>
        <div v-if="itm.type === 'timeline'">
          <div class="d-flex align-center">
            <h2>Timeline</h2>
            <div class="mt-3 mb-3 ml-4">
              <v-btn
                outlined
                :disabled="i === 0 && pageitems.length > 1"
                @click="moveItemUp(itm, i)"
              >
                <v-icon class="drag-icon"> mdi-arrow-up </v-icon>
              </v-btn>
              <v-btn
                outlined
                :disabled="
                  index === itm.value.length - 1 && itm.value.length > 1
                "
                @click="moveItemDown(itm, i)"
                class="ml-2"
              >
                <v-icon class="drag-icon"> mdi-arrow-down </v-icon>
              </v-btn>
              <v-btn @click="deleteItem(i)" color="primary" class="ml-5">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <v-text-field
              label="Timeline Title"
              variant="outlined"
              v-model="itm.title"
            ></v-text-field>
          </div>

          <div>
            <div v-for="(item, index) in itm.value" :key="index">
              <v-row class="mt-10">
                <v-col cols="12" sm="12" md="6" lg="6">
                  <h3 class="mb-6 d-flex align-center justify-space-between">
                    <span>
                      Timeline Item {{ index + 1 }}
                      <v-btn
                        outlined
                        :disabled="index === 0 && itm.value.length > 1"
                        @click="moveTimelineItemUp(itm.value, index)"
                        class="ml-3"
                      >
                        <v-icon class="drag-icon"> mdi-arrow-up </v-icon>
                      </v-btn>
                      <v-btn
                        outlined
                        :disabled="
                          index === itm.value.length - 1 && itm.value.length > 1
                        "
                        @click="moveTimelineItemDown(itm.value, index)"
                        class="ml-2"
                      >
                        <v-icon class="drag-icon"> mdi-arrow-down </v-icon>
                      </v-btn>
                    </span>
                    <span>
                      <v-btn
                        color="primary"
                        @click="deleteTimeLineItem(itm.value, index)"
                        class="timeline-delete-btn"
                      >
                        <v-icon dark> mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </span>
                  </h3>
                  <v-row gutters="16">
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="4" lg="4">
                      <v-text-field
                        label="Title"
                        variant="outlined"
                        v-model="item.title"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="4" lg="4">
                      <v-text-field
                        label="Year"
                        variant="outlined"
                        v-model="item.year"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="4" lg="4">
                      <v-text-field
                        label="Source Rating"
                        variant="outlined"
                        v-model="item.sourcerating"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="12" lg="12">
                      <v-text-field
                        label="Source Address"
                        variant="outlined"
                        v-model="item.sourceaddress"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="12" lg="12">
                      <h3>Content</h3>
                      <div class="position-relative mb-5">
                        <v-md-editor
                          v-model="item.content"
                          height="200px"
                          @save="editorSave"
                          left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link | save"
                        ></v-md-editor>
                        <v-btn
                          class="file-upload-btn"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click="openRefGetter(item, 'content')"
                        >
                          <v-icon>mdi-cloud-upload</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="12" lg="12">
                      <h3>Detail Content</h3>
                      <div class="position-relative">
                        <v-md-editor
                          v-model="item.detailcontent"
                          height="200px"
                          @save="editorSave"
                          left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link | save"
                        ></v-md-editor>
                        <v-btn
                          class="file-upload-btn"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click="openRefGetter(item, 'detailcontent')"
                        >
                          <v-icon>mdi-cloud-upload</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-timeline class="mt-10 mb-10" align="start" side="end">
                    <v-timeline-item color="primary">
                      <template v-slot:opposite>
                        <div
                          :class="`pt-2 headline font-weight-bold `"
                          v-text="item.year"
                        ></div>
                      </template>
                      <div style="max-width: 400px">
                        <h3 :class="`mt-n1 headline mb-3 `">
                          {{ item.title }}
                        </h3>
                        <div>
                          <!-- <Markdown
                            :breaks="true"
                            :html="true"
                            :source="item.content"
                          /> -->
                          <v-md-preview
                            style="padding: 0px"
                            :text="item.content"
                          />
                        </div>
                        <div class="mt-2" v-if="item.sourcerating">
                          <v-rating :model-value="item.sourcerating" readonly />
                        </div>
                        <div>
                          <v-btn
                            class="mr-2"
                            variant="outlined"
                            color="primary"
                            v-if="item.detailcontent.trim()"
                            @click="detailDialog(item.detailcontent.trim())"
                          >
                            Detail
                          </v-btn>
                          <!-- <v-btn variant="outlined" color="primary">
                            Check
                          </v-btn> -->
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
              <!-- <hr /> -->
            </div>
            <v-col class="mt-5 pl-0">
              <v-btn @click="addTimelineItem(itm.value)" color="primary">
                <v-icon>mdi-plus</v-icon>
                <v-icon>mdi-timeline-plus-outline</v-icon>
              </v-btn>
            </v-col>
          </div>
        </div>
        <!-- <hr /> -->
      </div>
      <div>
        <v-btn @click="addTextItem" color="primary">
          <v-icon>mdi-plus</v-icon> <v-icon>mdi-text</v-icon>
        </v-btn>
        <v-btn class="ml-5" @click="addTimeline" color="primary">
          <v-icon>mdi-plus</v-icon> <v-icon>mdi-timeline-text-outline</v-icon>
        </v-btn>
        <v-btn class="ml-5" @click="addCard" color="primary">
          <v-icon>mdi-plus</v-icon> <v-icon>mdi-card-bulleted-outline</v-icon>
        </v-btn>
        <v-btn
          class="ml-5"
          @click="saveArticle"
          :loading="saveloading"
          color="primary"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <router-link to="/articlesdashboard">
          <v-btn class="ml-15" color="primary">
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-btn>
        </router-link>
        <v-switch
          v-model="pagepublished"
          label="Published"
          color="primary"
          class="ml-15"
          @change="saveArticle"
          >Published</v-switch
        >
        <div v-if="pagepublished">
          <a :href='"https://ahmadiyyareferences.org/article/" + $route.params.id' target="_blank"
            >View Article</a>
        </div>
      </div>
    </div>
    <v-container>
      <h2>Full Article</h2>
      <hr />
      <ArticleView :pageitems="pageitems" :pagetitle="pagetitle" />
    </v-container>
    <v-dialog
      persistent=""
      fullscreen
      v-if="refgetterdialog"
      v-model="refgetterdialog"
    >
      <v-card>
        <v-card-title class="headline pb-3 justify-space-between">
          <!-- Get References -->
          <v-btn
            class="close-modal-btn"
            color="primary"
            fab
            x-small
            dark
            @click="refgetterdialog = false"
            aria-label="Ref Getter"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-0">
          <RefGetterVue
            :fieldtext="objref"
            :field="objreffield"
            :updatefunc="updateField"
            :scrollposition="scrollposition"
          />
          <!-- <AssistedGrading :questionindex="activeQuestionIndex" /> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      v-if="detaildialogtext && detaildialog && detaildialogtext.trim()"
      v-model="detaildialog"
    >
      <v-card>
        <v-card-title class="headline pb-3 justify-space-between">
          <!-- Get References -->
          <v-btn
            class="close-modal-btn"
            color="primary"
            fab
            x-small
            dark
            @click="
              detaildialog = false;
              detaildialogtext = '';
            "
            aria-label="Ref Getter"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-container>
            <v-md-preview :text="detaildialogtext" />
          </v-container>
          <!-- <AssistedGrading :questionindex="activeQuestionIndex" /> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RefGetterVue from "@/components/RefGetter.vue";
import ArticleView from "@/components/ArticleView.vue";
// import Markdown from "vue3-markdown-it";
import { post, get } from "@/apiClient";
export default {
  name: "Editor",
  components: {
    // Markdown,
    RefGetterVue,
    ArticleView
  },

  data() {
    return {
      cardstyles: [
      "outlined",
      "tonal",
      "flat",
      "elevated",
    ],
      detaildialogtext: "",
      detaildialog: false,
      toptext: "",
      timeline: [],
      bottomtext: "",
      pageitems: [],
      pagetitle: "Article about Dolphins",
      pagepublished: false,
      refgetterdialog: false,
      objref: null,
      objreffield: "value",
      saveloading: false,
      scrollposition: 0,
    };
  },
  mounted() {
    let route = this.$route;
    //write a long paragraph with a story using makrdown
    this.toptext = `## Dolphins: Enchanting Creatures of the Sea

Dolphins, with their grace and intelligence, hold a special place in our hearts. Here's a brief look at these remarkable marine mammals.

### Species and Habitat


**Diverse Species:** Over 40 species, including bottlenose, orca, and spinner dolphins.
**Wide Range:** Found in oceans, rivers, and various climate zones.


### Physical Features

**Streamlined Bodies:** Ideal for agile swimming.
**Distinctive Markings:** Dorsal fin, horizontal tail fluke, and blowhole for breathing.


### Intelligence and Social Behavior
**Highly Intelligent:** Known for problem-solving and complex communication.
**Social Creatures:** Form pods for hunting, play, and nurturing.`;

    this.timeline.push({
      title: "Title",
      year: "2021",
      content:
        "Pending changes protection should not be used as a preemptive measure against violations that have not yet occurred. Like semi-protection, PC protection should '''never''' be used in genuine content disputes, ",
      detailcontent: "Detail Content",
      sourceaddress: "Source Address",
      sourcerating: "5",
    });
    this.timeline.push({
      title: "Title",
      year: "2021",
      content:
        "Pending changes protection should not be used as a preemptive measure against violations that have not yet occurred. Like semi-protection, PC protection should '''never''' be used in genuine content disputes, ",
      detailcontent: "Detail Content",
      sourceaddress: "Source Address",
      sourcerating: "5",
    });

    this.bottomtext = `### Summary

Pending changes protection should not be used as a preemptive measure against violations that have not yet occurred. Like semi-protection, PC protection should '''never''' be used in genuine content disputes, where there is a risk of placing a particular group of editors (unregistered users) at a disadvantage. Pending changes protection should not be used on articles with a very high edit rate, even if they meet the aforementioned criteria. Instead, semi-protection should be considered.
`;

    this.pageitems.push({
      type: "text",
      value: this.toptext,
    });
    // this.pageitems.push({
    //   type: "timeline",
    //   value: this.timeline,
    //   title: "Example Timeline",
    // });
    // this.pageitems.push({
    //   type: "text",
    //   value: this.bottomtext,
    // });
    // console.log(this.pageitems);
    if (route.params.id && route.params.id != "new") {
      get("article/" + route.params.id + "/" + route.params.siteid).then((response) => {
        this.pagetitle = response.data.title;
        this.pageitems = response.data.items;
        this.pagepublished = response.data.published || false;
      });
    }
  },
  methods: {
    moveCardItemUp(item, index) {
      let temp = item[index - 1];
      item[index - 1] = item[index];
      item[index] = temp;
    },
    moveCardItemDown(item, index) {
      let temp = item[index + 1];
      item[index + 1] = item[index];
      item[index] = temp;
    },
    deleteCardItem(item, index) {
      item.splice(index, 1);
    },
    addCardItem(item) {
      item.push({
        header: "",
        link: "",
        content: "",
        linknewwindow: false,
      });
    },
    detailDialog(text) {
      this.detaildialogtext = text;
      this.detaildialog = true;
    },
    imageClick: function (event) {
      // console.log(event);
    },
    editorSave: function () {
      // console.log(value);
      this.saveArticle();
    },
    moveItemUp(item, index) {
      let temp = this.pageitems[index - 1];
      this.pageitems[index - 1] = this.pageitems[index];
      this.pageitems[index] = temp;
    },
    moveItemDown(item, index) {
      let temp = this.pageitems[index + 1];
      this.pageitems[index + 1] = this.pageitems[index];
      this.pageitems[index] = temp;
    },
    moveTimelineItemDown(item, index) {
      let temp = item[index + 1];
      item[index + 1] = item[index];
      item[index] = temp;
    },
    moveTimelineItemUp(item, index) {
      let temp = item[index - 1];
      item[index - 1] = item[index];
      item[index] = temp;
    },
    deleteItem(index) {
      this.pageitems.splice(index, 1);
    },
    deleteTimeLineItem(item, index) {
      item.splice(index, 1);
    },
    saveArticle() {
      let route = this.$route;

      let article = {
        key: route.params.id,
        title: this.pagetitle,
        items: this.pageitems,
        published: this.pagepublished,
      };
      this.saveloading = true;
      post("article/" + route.params.id + "/" + route.params.siteid, article).then((response) => {
        // console.log(response);
        if (route.params.id != response.data.key) {
          this.$router.push({
            name: "Editor",
            params: { id: response.data.key },
          });
        }
        this.saveloading = false;
      }).catch((err) => {
        console.log(err);
        this.saveloading = false;
        alert("Error saving article");
      });
      //   console.log(this.pageitems);
    },
    openRefGetter(objref, field) {
      this.scrollposition = window.scrollY;
      this.objref = objref;
      this.objreffield = field;
      this.refgetterdialog = true;
    },
    updateField(field, newtext) {
      field[this.objreffield] = newtext;
      this.refgetterdialog = false;
    },
    cleanText(text) {
      // replace two new lines with br
      //   text = text.replace(/\n\n\n/g, "<br />");
      text = text.replace(/\\np/g, "<br />");
      text = text.replace(/\\np\n/g, "<br />");

      return text;
    },
    addTextItem() {
      this.pageitems.push({
        type: "text",
        value: "",
      });
    },
    addCard() {
      this.pageitems.push({
        type: "card",
        style: "tonal",
        value: [
          {
            header: "",
            link: "",
            content: "",
            linknewwindow: false,
          },
        ],
        widthsm: "12",
        widthmd: "6",
        widthlg: "4",
      });
    },
    addTimeline() {
      this.pageitems.push({
        type: "timeline",
        value: [],
        title: "",
        // content: " ",
        // detailcontent: " ",
      });
    },
    addTimelineItem(item) {
      item.push({
        title: "",
        year: "",
        content: " ",
        detailcontent: " ",
        sourceaddress: "",
        sourcerating: "5",
      });
    },
  },
};
</script>

<style scoped>
.drag-icon {
  font-size: 20px;
}
.mdimg {
  border: 1px solid #ccc;
}
.position-relative {
  position: relative;
}
.file-upload-btn {
  right: 51%;
  top: 2px;
  position: absolute;
}
.sectionheading {
  border: 1px solid #bababa;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}
.github-markdown-body {
  padding: 0px;
}
</style>